import { RemixBrowser } from '@remix-run/react';
import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

// fixup stuff before hydrating
function hydrate(): void {
	React.startTransition(() => {
		hydrateRoot(document, <RemixBrowser />);
	});
}

document
	.querySelectorAll(
		'html > script, html > input, html > loom-container, html > grammarly-desktop-integration',
	)
	.forEach((s) => {
		s.parentNode?.removeChild(s);
	});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	window.setTimeout(hydrate, 1);
}
/*

import { RemixBrowser } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';
import { hydrate } from 'react-dom';

declare global {
	var Cypress: any;
}

document
	.querySelectorAll(
		'html > script, html > input, html > loom-container, html > grammarly-desktop-integration',
	)
	.forEach((s) => {
		s.parentNode?.removeChild(s);
	});

if (window.Cypress) {
	hydrate(<RemixBrowser />, document);
} else {
	hydrateRoot(document, <RemixBrowser />);
}
*/
